import "./AvailableProductsTable.scss";

import i2Open from "../../assets/images/products/i2Open.jpg";
import i2OpenInnfelt from "../../assets/images/products/i2Open_innfellbar.jpg";
import { useState } from "react";
import { faMinusCircle, faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { OrderFormStep1Props } from "./MultistepOrderForm";

export default function AvailableProductsTable({
  nrOf10002,
  nrOf10003,
  setNrOf10002,
  setNrOf10003,
}: OrderFormStep1Props) {
  return (
    <div
      className="available-products-table"
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "30px repeat(2, 1fr)",
        maxHeight: "700px",
      }}
    >
      <div className="available-products-header">
        {/*<h3>Hovedprodukter</h3>*/}
        <p>Art.nr</p>
        <p>Beskrivelse</p>
        <p>Bilde</p>
        <p>Pris</p>
        <p>Antall</p>
      </div>
      <AvailableProductsTableRow
        srnr={10002}
        text={'"i2Open" Berøringsfri Døråpner, hvit, for montering i tak/vegg'}
        imagePath={i2Open}
        price={3700}
        number={nrOf10002}
        setNumber={setNrOf10002}
      />
      <AvailableProductsTableRow
        srnr={10003}
        text={'"i2Open" Berøringsfri Døråpner, hvit, for innfelling i himling'}
        imagePath={i2OpenInnfelt}
        price={3700}
        number={nrOf10003}
        setNumber={setNrOf10003}
      />
    </div>
  );
}

interface AvailableProductsTableRowProps {
  srnr: number;
  text: string;
  imagePath: string;
  price: number;
  number: number;
  setNumber: (nr: number) => void;
}

export function AvailableProductsTableRow({
  srnr,
  text,
  imagePath,
  price,
  number,
  setNumber,
}: AvailableProductsTableRowProps) {
  return (
    <div
      className="available-products-table-row"
      style={{
        display: "grid",
        gridTemplateColumns: "50px 5fr 1fr 1fr 75px",
        gridTemplateRows: "1fr",
        gap: "10px",
      }}
    >
      <div>{srnr}</div>
      <div>{text}</div>
      <div>
        {imagePath && (
          <div className="available-products-image-container">
            <img
              className="available-products-image"
              src={imagePath}
              alt="Bilde av i2Open"
            />
          </div>
        )}
      </div>
      <div>{price} Nok</div>
      <div className="available-products-order-status">
        <FontAwesomeIcon
          onClick={() => {
            if (number > 0) {
              setNumber(number - 1);
            }
          }}
          icon={faMinusCircle}
          className="available-products-control-button"
          size="lg"
        />
        {number}
        <FontAwesomeIcon
          icon={faPlusCircle}
          size="lg"
          className="available-products-control-button"
          onClick={() => setNumber(number + 1)}
        />
      </div>
    </div>
  );
}
